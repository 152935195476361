<template>
  <div class="page">
    <Navbar mode="solid" class="fixed top-0 left-0 z-20"/>
    <main class="page-content">
      <div class="page-container">
        <h2 class="page-title">
          {{ $t("signup.title") }}
        </h2>
        <form
            @submit.prevent="registerSubmit"
            method="post"
            action=""
            novalidate="true"
        >
          <div class="signup-input-container">
            <InputText
                class="signup-input-contained"
                :label="`${$t('signup.input_1.label')}`"
                :placeholder="`${$t('signup.input_1.placeholder')}`"
                name="first-name"
                :disabled="loading"
                :errorMsg="firstNameErrMsg"
                :showErrMsg="$v.form.firstName.$error"
                @blur="$v.form.firstName.$touch()"
                v-model="form.firstName"
            />

            <InputText
                class="signup-input-contained"
                :label="`${$t('signup.input_2.label')}`"
                :placeholder="`${$t('signup.input_2.placeholder')}`"
                :disabled="loading"
                name="last-name"
                :errorMsg="lastNameErrMsg"
                :showErrMsg="$v.form.lastName.$error"
                @blur="$v.form.lastName.$touch()"
                v-model="form.lastName"
            />
          </div>

          <InputText
              :label="`${$t('signup.input_3.label')}`"
              :placeholder="`${$t('signup.input_3.placeholder')}`"
              type="email"
              name="email"
            :errorMsg="emailErrMsg"
            :showErrMsg="$v.form.email.$error"
            @blur="$v.form.email.$touch()"
            v-model="form.email"
              class="signup-input"
          />

          <InputText
              :label="`${$t('signup.input_4.label')}`"
              :placeholder="`${$t('signup.input_4.placeholder')}`"
              type="password"
              name="password"
              :errorMsg="passwordErrMsg"
              :showErrMsg="$v.form.password.$error"
              @blur="$v.form.password.$touch()"
              v-model="form.password"
              class="signup-input"
          />

          <InputText
              :label="`${$t('signup.input_5.label')}`"
              :placeholder="`${$t('signup.input_5.placeholder')}`"
              type="password"
              name="confirm_password"
              :errorMsg="confirmPasswordErrMsg"
              :showErrMsg="$v.form.password_confirmation.$error"
              @blur="$v.form.password_confirmation.$touch()"
              v-model="form.password_confirmation"
              class="signup-input"
          />

          <Checkbox
              :showErrMsg="$v.form.terms.$error"
              @input="$v.form.terms.$touch()"
              v-model="form.terms"
              :showSignupLabel="true"
              :showDefaultLabel="false"
          />

          <ButtonPrimary
              type="submit"
              :text="`${$t('signup.button_1')}`"
              :loading="loading"
              class="w-full"
          />

          <div class="flex justify-center w-full mt-6 font-sans text-sm">
            <span class="text-sm text-black-base">{{ $t("trip_request.form_6.login.label") }}</span>
            <router-link :to="`/login?lang=${lang}`"
               class="btn-link"
            >
              {{ $t("signup.button_2") }}
            </router-link>
          </div>
        </form>
      </div>

    </main>
    <BottomBar class="layout-bar"/>
  </div>
</template>

<script>

import Checkbox from "@/components/checkboxes/Checkbox";
import BottomBar from "@/components/layout/BottomBar";
import InputText from "@/components/inputs/InputText";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import {mapActions} from "vuex";
import {
  required,
  email,
  alpha,
  sameAs,
  minLength,
} from "vuelidate/lib/validators";

export default {
  components: {
    BottomBar,
    Checkbox,
    InputText,
    ButtonPrimary,
},
  data() {
    return {
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
      form: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        password_confirmation: "",
        terms: false,
      },
      loading: false,
    }
  },
  name: "Login.vue",
  validations: {
    form: {
      firstName: {
        required,
        alpha,
        minLength: minLength(2),
      },
      lastName: {
        required,
        alpha,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAs: sameAs("password"),
      },
      terms: {
        required,
        checked: (val) => {
          return val;
        },
      },
    },
  },
  computed: {
    firstNameErrMsg() {
      let msg;
      if (!this.$v.form.firstName.required) {
        //First name is required
        msg = this.$t("signup.input_1.error_1");
      }
      if (!this.$v.form.firstName.minLength) {
        //First name must have at least {count} characters
        let count = this.$v.form.firstName.$params.minLength.min;
        msg = this.$tc("signup.input_1.error_2", count, {count: count});
      }
      if (!this.$v.form.firstName.alpha) {
        //First name must only contain alphabetic characters
        msg = this.$t("signup.input_1.error_3");
      }
      return msg;
    },
    lastNameErrMsg() {
      let msg;
      if (!this.$v.form.lastName.required) {
        //Last name is required
        msg = this.$t("signup.input_2.error_1");
      }
      if (!this.$v.form.lastName.minLength) {
        //Last name must have at least {count} characters
        let count = this.$v.form.lastName.$params.minLength.min;
        msg = this.$tc("signup.input_2.error_2", count, {count: count});
      }
      if (!this.$v.form.lastName.alpha) {
        //Last name must only contain alphabetic characters
        msg = this.$t("signup.input_2.error_3");
      }
      return msg;
    },
    emailErrMsg() {
      let msg;
      if (!this.$v.form.email.email) {
        msg = this.$t("signup.input_3.error_1");
      }
      if (!this.$v.form.email.required) {
        msg = this.$t("signup.input_3.error_2");
      }
      return msg;
    },
    passwordErrMsg() {
      let msg;
      if (!this.$v.form.password.required) {
        msg = this.$t("signup.input_4.error_1");
      }
      if (!this.$v.form.password.minLength) {
        let count = this.$v.form.password.$params.minLength.min;
        msg = this.$tc("signup.input_4.error_2", count, {count: count});
      }
      return msg;
    },
    confirmPasswordErrMsg() {
      let msg;
      if (!this.$v.form.password_confirmation.required) {
        msg = this.$t("signup.input_5.error_1");
      }
      if (!this.$v.form.password_confirmation.sameAs) {
        msg = this.$t("signup.input_5.error_2");
      }
      return msg;
    },
  },
  methods: {
    ...mapActions("auth", ["register"]),
    async registerSubmit() {
      this.loading = true;
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return false;
        }

        const data = {
          name: `${this.form.firstName} ${this.form.lastName}`,
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
        };

        const res = await this.register(data);
        if (!res) return false;
        //await this.trackSignUp(data);
        this.loading = false;
        this.$router.push({
          name: "Account",
        });
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped>
.page-content {
  @apply pb-24 sm:pb-0 px-4 py-12 md:px-12 mt-20;
}

.page-container {
  @apply w-full max-w-md;
  @apply relative transform -translate-x-1/2 left-1/2;
}

.page-title {
  @apply font-sans text-2xl font-semibold text-black-base;
  @apply mb-8;
}

.signup-input-container {
  @apply flex justify-between flex-wrap;
}

.signup-input {
  @apply mb-4;
}

.signup-input-contained {
  @apply w-full mb-4;
}

.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden;
}

.btn-link {
  @apply font-sans font-semibold text-right text-sm text-teal-dark hover:text-teal-base;
  @apply block mb-6 ml-2 mr-auto;
  @apply transition duration-300 ease-in-out;
}
</style>